<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="createSet">
      <b-form
        class="mt-2"
        @submit.prevent="handleSubmit"
        @keydown.enter="handleSubmit"
      >
        <b-row>
          <b-col cols="12">
            <h4 class="mb-2" >{{ $t('DetallesSet') }}</h4>
          </b-col>
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              :name="$t('Nombre')"
              rules="required"
            >
              <b-form-group
                :label="$t('Nombre')"
                label-for="set-name"
              >
                <b-form-input
                  v-model="name"
                  name="name"
                  :placeholder="$t('Nombre')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="12">
            <validation-provider
              #default="{ errors }"
              :name="$t('Descripcion')"
              rules="required"
            >
              <b-form-group
                :label="$t('Descripcion')"
                label-for="set-description"
              >
                <quill-editor
                  v-model="description"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col cols="12" >
            <p>{{ $t('Productos') }}</p>
            <b-row>
              <draggable-list-products />
              <b-col cols="6">
                <b-list-group class="cursor-move box-drag-products-parent" >
                  <b-list-group-item class="d-flex justify-content-between align-items-center">
                    <h5 class="font-weight-bolder mb-0" >{{ $t('Productos') }}</h5>
                  </b-list-group-item>
                  <draggable
                    :list="productsSet"
                    :group="{name: 'products'}"
                    @add="handleChange"
                    @end="handleChange"
                    @remove="handleChange"
                    @move="handleChange"
                  >
                    <b-list-group-item v-if="p.type == 'product'" v-for="( p, index) in productsSet" :key="index" class="d-flex justify-content-between align-items-center">
                      <span>{{ p.name }}</span>
                      <b-button
                          variant="outline-danger"
                          @click="removeProduct(index)"
                      >
                        <feather-icon icon="TrashIcon" size="16" />
                      </b-button>
                    </b-list-group-item>
                  </draggable>
                  <b-list-group-item class="d-flex justify-content-between align-items-center">
                    <h5 class="font-weight-bolder mb-0" >{{ $t('Activos') }}</h5>
                  </b-list-group-item>
                  <draggable
                    :list="productsSet"
                    :group="{name: 'products'}"
                    @add="handleChange"
                    @end="handleChange"
                    @remove="handleChange"
                    @move="handleChange"
                  >
                    <b-list-group-item v-if="p.type == 'asset'" v-for="( p, index) in productsSet" :key="index" class="d-flex justify-content-between align-items-center">
                      <span>{{ p.name }}</span>
                      <b-button
                          variant="outline-danger"
                          @click="removeProduct(index)"
                      >
                        <feather-icon icon="TrashIcon" size="16" />
                      </b-button>
                    </b-list-group-item>
                  </draggable>
                  <b-list-group-item class="d-flex justify-content-between align-items-center">
                    <h5 class="font-weight-bolder mb-0" >{{ $t('Agrupaciones') }}</h5>
                  </b-list-group-item>
                  <draggable
                    :list="productsSet"
                    :group="{name: 'products'}"
                    @add="handleChange"
                    @end="handleChange"
                    @remove="handleChange"
                    @move="handleChange"
                  >
                    <b-list-group-item v-if="p.type == 'set'" v-for="( p, index) in productsSet" :key="index" class="d-flex justify-content-between align-items-center">
                      <span>{{ p.name }}</span>
                      <b-button
                          variant="outline-danger"
                          @click="removeProduct(index)"
                      >
                        <feather-icon icon="TrashIcon" size="16" />
                      </b-button>
                    </b-list-group-item>
                    <div class="box-drag-products" >
                      <feather-icon icon="UploadIcon" size="16" />
                      <p class="m-0" >{{ $t('ArrastraProductos') }}</p>
                    </div>
                  </draggable>
                </b-list-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12 text-right">
            <b-button
              type="submit"
              variant="primary"
              class="mt-2 mr-1"
            >
              {{ $t('Enviar') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import { quillEditor } from 'vue-quill-editor'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import DraggableListProducts from '@/views/products/DraggableListProducts'
import draggable from 'vuedraggable'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    DraggableListProducts,
    draggable,
    BListGroup,
    BListGroupItem,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      name: '',
      description: '',
      productsSet: [],
      required,
      email,
    }
  },
  computed: {
    ...mapGetters({
      currentClient: 'clients/getCurrentClient',
      currentCompany: 'companies/getCurrentCompany',
    }),
  },
  methods: {
    ...mapActions({
      create: 'sets/create',
    }),
    handleSubmit() {
      this.$refs.createSet.validate().then(success => {
        if (success) {
          const formData = this.createFormData()
          if (formData) {
            this.create({ set: formData })
          }
        }
      })
    },
    createFormData() {
      const data = new FormData()
      data.append('name', this.name)
      data.append('description', this.description)
      data.append('client_id', this.currentClient.id)
      data.append('company_id', this.currentCompany.id)
      if (this.productsSet.length > 0) {
        this.productsSet.forEach(element => {
          if (element.type === 'product') {
            data.append('products[]', JSON.stringify(element))
          }
          if (element.type === 'asset') {
            data.append('assets[]', JSON.stringify(element))
          }
        })
      } else {
        data.append('products', '')
        data.append('assets', '')
      }

      return data
    },
    removeProduct(index) {
      this.productsSet.splice(index, 1)
    },
    handleChange() {
      const productsSetAux = []
      this.productsSet.forEach((element, index) => {
        productsSetAux[index] = {
          order: index + 1,
          id: element.id,
          type: element.type,
          name: element.name,
        }
      })
      this.productsSet = productsSetAux
    },
  },
}
</script>
